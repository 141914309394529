<template>
  <VTable
    :head-row="USER_TABLE_HEAD"
    url-set-rows="/users"
    :touch-bar-list="touchBarAllUsers"
    :url-action-query="USER_EXPAND_TABLE"
    url-action="/users/action"
    @link="handlerLink"
  />
</template>

<script setup>
import VTable from '@/components/ui/table/VTable'

import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

import { touchBarAllUsers } from '@/utils/touchbar'
import { USER_EXPAND_TABLE, USER_TABLE_HEAD } from '@/utils/consts/User'

const props = defineProps({
  routeName: {
    type: String,
    required: false,
    default: 'admin-user'
  }
})

const router = useRouter()

const handlerLink = (row) => {
  router.push({ name: props.routeName, params: { id: row.item.id } })
}
</script>
